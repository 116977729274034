import CompanyDescription from "./CompanyDescription";
import ServicesList from "./ServicesList";

import Style from "./Services.module.css";
import { forwardRef } from "react";

const ServicesSection = forwardRef((props, ref) => {
  return (
    <div ref={ref} id="services" className={Style["services-section-wrapper"]}>
      <CompanyDescription />
      <ServicesList />
    </div>
  );
});

export default ServicesSection;
