import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Style from "./BrandSection.module.css";
import ValueItem from "./ValueItem";
import {
  faAnglesUp,
  faBusinessTime,
  faHandshake,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";

const BrandSection = () => {
  return (
    <div className={Style["brand-section-wrapper"]}>
      <div className={Style["brand-section-content"]}>
        <div className={Style["motto-container"]}>
          <h1>SHAPING TOMORROW'S WORLD</h1>
          <div>
            <div className={Style["delimiter"]}></div>
            <h2>YOUR SUCCESS IS OUR MISSION</h2>
          </div>
        </div>
        <div className={Style["values-container"]}>
          <div className={Style["arrows"]}>
            <FontAwesomeIcon icon={faAnglesUp} size="4x" color="#FFDBCB" />
          </div>
          <div className={Style["values-list"]}>
            <ValueItem
              text={"PROFESSIONALISM AND DEDICATION"}
              icon={faBusinessTime}
            />
            <ValueItem text={"HIGHLY SKILLED TEAM"} icon={faPeopleGroup} />
            <ValueItem text={"FRIENDLY ATTITUDE"} icon={faHandshake} />
          </div>
        </div>
      </div>

      <div className={Style["image-container"]}></div>
    </div>
  );
};

export default BrandSection;
