import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faPhoneVolume, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import useScreenSize from "../../hooks/useScreenSize";

import Style from "./Footer.module.css";

const ContactsElement = ({ icon, href, text, openNewTab }) => {
  return (
    <a
      href={href}
      target={openNewTab ? "_blank" : ""}
      rel="noreferrer"
      className={Style["contacts-element"]}
    >
      <FontAwesomeIcon icon={icon} size="lg" style={{ marginRight: "20px" }} />
      {text || <span>{text}</span>}
    </a>
  );
};

const FooterDesktop = ({ items }) => {
  return (
    <footer className={Style.footer}>
      <div className={Style["footer__inner-container"]}>
        <div>
          {items.map((i) => {
            return (
              <ContactsElement
                key={i.text}
                icon={i.icon}
                text={i.text}
                href={i.href}
                openNewTab={i.openNewTab}
              />
            );
          })}
        </div>
        <div>
          <nav>
            <Link to="/">Home</Link> &nbsp;|&nbsp;
            <Link to="/services">Services</Link> &nbsp;|&nbsp;
            <Link to="/technologies">Technologies</Link> &nbsp;|&nbsp;
            <Link to="/about-us">About us</Link>
            &nbsp;|&nbsp;<Link to="/contact-us">Contact us</Link>
          </nav>
          <img
            className={Style.logo}
            src="logo-white-with-name.png"
            alt="Logo"
          />
          <span> &#169; Warenex Ltd {new Date().getFullYear()}</span>
        </div>
      </div>
    </footer>
  );
};

const FooterMobile = ({ items }) => {
  return (
    <footer className={Style.footer}>
      <nav className={Style["mobile-navigation"]}>
        <img className={Style.logo} src="logo-white-with-name.png" alt="Logo" />
        <Link to="/">Home</Link>
        <Link to="/services">Services</Link>
        <Link to="/technologies">Technologies</Link>
        <Link to="/about-us">About us</Link>
        <Link to="/contact-us">Contact us</Link>
      </nav>
      <div className={Style["mobile-contacts"]}>
        {items.map((i) => {
          return (
            <ContactsElement
              key={i.text}
              icon={i.icon}
              href={i.href}
              openNewTab={i.openNewTab}
            />
          );
        })}
        <span> &#169; Warenex Ltd {new Date().getFullYear()}</span>
      </div>
    </footer>
  );
};

const Footer = () => {
  const { isSmallSize, isMediumSize } = useScreenSize();

  const isMobile = isSmallSize || isMediumSize;

  const contactElements = [
    {
      text: "https://www.facebook.com/warenexltd",
      href: "https://www.facebook.com/warenexltd",
      icon: faFacebook,
      openNewTab: true,
    },
    {
      text: "+359 87 731 0641",
      href: "tel:+359 87 731 0641",
      icon: faPhoneVolume,
    },
    {
      text: "contact@warenex.com",
      href: "mailto:contact@warenex.com",
      icon: faEnvelope,
    },
  ];

  return isMobile ? (
    <FooterMobile items={contactElements} />
  ) : (
    <FooterDesktop items={contactElements} />
  );
};

export default Footer;
