import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Style from "./ValuesItem.module.css";
import useScreenSize from "../../../hooks/useScreenSize";

const ValueItemMobile = ({ text, icon }) => {
  return (
    <div className={Style["value-item-container-mobile"]}>
      <FontAwesomeIcon icon={icon} size="2x" />
      <p>{text}</p>
      <div className={Style["delimiter"]}></div>
    </div>
  );
};

const ValueItemDesktop = ({ text, icon }) => {
  return (
    <div className={Style["value-item-container"]}>
      <p>{text}</p>
      <div className={Style["icons"]}>
        <div className={Style["delimiter"]}></div>
        <FontAwesomeIcon icon={icon} size="2x" />
      </div>
    </div>
  );
};

const ValueItem = ({ text, icon }) => {
  const { isMediumSize, isSmallSize } = useScreenSize();

  return isMediumSize || isSmallSize ? (
    <ValueItemMobile text={text} icon={icon} />
  ) : (
    <ValueItemDesktop text={text} icon={icon} />
  );
};

export default ValueItem;
