import { useState } from "react";
import Style from "./Strengths.module.css";

const Strength = ({ heading, text, boxColor, imageSrc }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={Style["strength-item"]}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className={`${Style["strength-item__image"]} ${
          !hovered ? "gray-out" : ""
        }`}
        style={{ backgroundImage: `url(${imageSrc})` }}
      ></div>
      <div
        className={Style["strength-item__box"]}
        style={{ backgroundColor: hovered ? "#000" : boxColor }}
      ></div>
      <div className={Style["strength-item__box-text-container"]}>
        <h3>{heading}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Strength;
