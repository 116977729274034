import { useEffect } from "react";
import IntroductionWithImagesSection from "../components/AboutUs/IntroductionWithImagesSection";
import ContactsSection from "../components/Contacts/ContactsSection";
import DescriptionWithImageSection from "../components/Common/DescriptionWithImage";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div>
      <DescriptionWithImageSection
        title={
          <h2>
            Here are some things
            <br /> <span style={{ color: "#0668E1" }}>about us </span>
          </h2>
        }
        paragraph={
          <p>
            We are a software company focused on providing{" "}
            <span style={{ color: "#0668E1" }}>innovative</span> technology
            solutions to our clients. We specialize in custom software
            development services. <br />
            <br /> At <span style={{ color: "#0668E1" }}>Warenex</span>, we
            understand that every client has unique needs, which is why we work
            closely with each client to understand their business requirements
            and create customized solutions to help them achieve their goals.
          </p>
        }
        showButton={false}
        imageInfo={{
          link: "/about_us_header.webp",
          description: "About us introduction picture.",
        }}
      />
      <IntroductionWithImagesSection />
      <ContactsSection />
    </div>
  );
};

export default AboutUs;
