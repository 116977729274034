import React, { useState } from "react";
import { Hourglass } from "react-loader-spinner";

const LoadableImage = ({
  containerClassName,
  containerStyle,
  imgClassName,
  imgStyle,
  src,
  alt,
  loaderHeight = 50,
  loaderWidth = 50,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className={containerClassName} style={containerStyle}>
      <Hourglass
        visible={!isLoaded}
        height={loaderHeight}
        width={loaderWidth}
        ariaLabel="hourglass-loading"
        colors={["#306cce", "#72a1ed"]}
      />
      <img
        className={imgClassName}
        onLoad={() => setIsLoaded(true)}
        src={src}
        style={isLoaded ? { ...imgStyle } : { display: "none", ...imgStyle }}
        alt={alt}
      />
    </div>
  );
};

export default LoadableImage;
