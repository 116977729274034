import { Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home";
import Technologies from "./pages/Technologies";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";

function App() {
  return (
    <Layout>
      <Route exact path={["/", "/home"]} component={Home} />
      <Route
        path={"/contact-us"}
        component={() => <Home scrollToContactUs />}
      />
      <Route path="/technologies" component={Technologies} />
      <Route path="/about-us" component={AboutUs} />
      <Route path="/services" component={Services} />
    </Layout>
  );
}

export default App;
