import Service from "./Service";

import Style from "./Services.module.css";

const ServicesSection = () => {
  const services = [
    {
      title: "Web development",
      titleColor: "#003CA2",
      description:
        "Our web development team crafts responsive, high-performance websites using cutting-edge technologies. We help you establish a robust online presence to engage and attract your target audience.",
    },
    {
      title: "Mobile development",
      titleColor: "#035DCB",
      description:
        "We specialize in creating intuitive, user-friendly mobile applications for iOS and Android. Our mobile development services ensure your business stays connected and accessible to your customers on-the-go.",
    },
    {
      title: "UX/UI design",
      titleColor: "#00A2FF",
      description:
        "Our designers focus on creating visually appealing, user-centered designs that enhance usability and user satisfaction. We bridge the gap between functionality and aesthetics, ensuring your digital products provide an unforgettable experience.",
    },
    {
      title: "QA testing",
      titleColor: "#00CBFF",
      description:
        "Our testing process ensures the reliability, security, and performance of your software products. We identify and resolve potential issues, delivering flawless solutions that stand the test of time.",
    },
  ];

  return (
    <div className={Style["services-section"]}>
      <div>
        {services.map((s) => (
          <Service
            key={s.title}
            title={s.title}
            titleColor={s.titleColor}
            description={s.description}
          />
        ))}
      </div>
    </div>
  );
};

export default ServicesSection;
