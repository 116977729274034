import ReasonsForActionsCard from "./ReasonsForActionsCard";
import Style from "./ReasonsForActions.module.css";

const reasons = [
  {
    imageSrc: "/solo-air.webp",
    description:
      "Working with Warenex has been an absolute pleasure for us. The employee management system they built for us exceeded our expectations in every way. We couldn't be happier with the results.",
  },
  {
    imageSrc: "/modern-house.webp",
    description:
      "Choosing Warenex to redesign our website has proven to be an excellent decision. The new design is not only visually stunning but also incredibly responsive across all devices. We received countless compliments from our customers.",
  },
];

const ReasonsForActionsSection = () => {
  return (
    <div
      className={Style["reasons-section-wrapper"]}
      style={{ backgroundImage: `url("/testimonial_background.webp")` }}
    >
      <h2>SOME OF THE REASONS WE ARE DOING THIS</h2>
      <div className={Style["section-body"]}>
        {reasons.map((reason) => (
          <ReasonsForActionsCard
            imageSrc={reason.imageSrc}
            description={reason.description}
          />
        ))}
      </div>
    </div>
  );
};

export default ReasonsForActionsSection;
