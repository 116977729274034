import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useScreenSize from "../../hooks/useScreenSize";

import Style from "./Contacts.module.css";

const ContactsElement = ({ icon, heading, text, contacts, href }) => {
  const { isExtraSmallSize } = useScreenSize();

  return (
    <div className={Style["contacts-element"]}>
      <div>
        <FontAwesomeIcon
          icon={icon}
          color="#0668E1"
          size={isExtraSmallSize ? "2xl" : "3x"}
        />
      </div>
      <h3>{heading}</h3>
      <p>{text}</p>
      <a href={href}>{contacts}</a>
    </div>
  );
};

export default ContactsElement;
