import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import useScreenSize from "../../hooks/useScreenSize";

import Style from "./DescriptionWithImage.module.css";
import LoadableImage from "../UI/LoadableImage";

const DescriptionWithImageSection = ({
  title,
  paragraph,
  showButton,
  imageInfo,
  onBtnClick,
}) => {
  const [hovered, setHovered] = useState(false);

  const { isLargeSize } = useScreenSize();

  let buttonColor = hovered ? "#000" : "#fff";
  let buttonTextColor = hovered ? "#fff" : "#000";
  let buttonArrowColor = hovered ? "#fff" : "#000";

  return (
    <div className={Style["vision-section-wrapper"]}>
      <div className={Style["vision-section-text-wrapper"]}>
        {title}
        {paragraph}
        {showButton && isLargeSize && (
          <button
            type="button"
            style={{ backgroundColor: buttonColor }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={onBtnClick}
          >
            <span style={{ padding: "2rem", color: buttonTextColor }}>
              WHAT WE USE
            </span>{" "}
            <FontAwesomeIcon
              icon={faAngleDown}
              size="lg"
              color={buttonArrowColor}
            />
          </button>
        )}
      </div>

      <LoadableImage
        containerClassName={Style["img-container"]}
        src={imageInfo.link}
        alt={imageInfo.description}
      />
    </div>
  );
};

export default DescriptionWithImageSection;
