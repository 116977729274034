import {
  faAnglesRight,
  faEnvelope,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import ContactsElement from "./ContactsElement";

import Style from "./Contacts.module.css";
import { forwardRef } from "react";
import PrimaryButton from "../UI/Buttons/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactsSection = forwardRef((props, ref) => {
  return (
    <div ref={ref} className={Style["contacts-section-wrapper"]}>
      <h1 className={Style["contact-us-heading"]}>SHARE YOUR IDEA WITH US</h1>
      <div className={Style["book-btn-container"]}>
        <PrimaryButton
          padding="2rem 5rem"
          fontSize="2rem"
          onClick={() =>
            window.open("https://calendly.com/contact-warenex/30min")
          }
        >
          Book a meeting &nbsp;
          <FontAwesomeIcon icon={faAnglesRight} size="2x" color="#fff" />
        </PrimaryButton>
      </div>
      <h2>OR GET IN TOUCH WITH US DIRECTLY</h2>
      <div className={Style["contacts-elements-container"]}>
        <ContactsElement
          icon={faPhoneVolume}
          heading="Make a call"
          text="Feel free to give us a call for a direct conversation with our team.  "
          contacts="+359 87 731 0641"
          href="tel:+359 87 731 0641"
        />
        <div className={Style["stroke"]}></div>
        <ContactsElement
          icon={faEnvelope}
          heading="Send us an E-mail"
          text="Reach out to us by email for a detailed response to your inquiries."
          contacts="contact@warenex.com"
          href="mailto:contact@warenex.com"
        />
      </div>
    </div>
  );
});

export default ContactsSection;
