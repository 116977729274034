import Service from "./Service";
import * as icons from "../../../icons";

import Style from "./Services.module.css";
import useScreenSize from "../../../hooks/useScreenSize";

const ServicesList = () => {
  const { isSmallSize, isMediumSize } = useScreenSize();

  const isMobile = isSmallSize || isMediumSize;

  return (
    <div
      className={Style["services-list"]}
      style={{ flexDirection: isMobile ? "column" : "row" }}
    >
      <Service
        title="CONSULTING"
        text="We analyze requirements, establish goals, and outline the project workflow to ensure success."
        icon={icons.bulbIcon}
      />
      <Service
        title="DESIGN"
        text="Our designers make easy-to-use and good-looking interfaces that work well on many devices."
        icon={icons.designIcon}
      />
      <Service
        title="DEVELOPMENT"
        text="Using cutting-edge technologies, we deliver high-performance software solutions for your needs."
        icon={icons.developIcon}
      />
      <Service
        title="TESTING"
        text="Our testing phase catches any potential issues and ensuring that the software meets the expectations."
        icon={icons.testIcon}
      />
      <Service
        title="SUPPORT"
        text="We keep your software working well, update it when needed, and fix any problems that come up."
        icon={icons.supportIcon}
      />
    </div>
  );
};

export default ServicesList;
