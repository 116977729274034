import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Style from "./ReasonsForActions.module.css";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

const ReasonsForActionsCard = ({ imageSrc, description }) => {
  return (
    <div className={Style["card-wrapper"]}>
      <div className={Style["card-header"]}>
        <img src={imageSrc} alt="" />
        <FontAwesomeIcon
          icon={faQuoteLeft}
          style={{ fontSize: "3.2rem" }}
          color="white"
        />
      </div>
      <div className={Style["card-body"]}>
        <p>{description}</p>
      </div>
      <div className={Style["card-footer"]}></div>
    </div>
  );
};
export default ReasonsForActionsCard;
