import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useScreenSize from "../../hooks/useScreenSize";

import Style from "./Technologies.module.css";

const TechnologyListItem = ({ name, isReversed, icon }) => {
  const { isExtraSmallSize } = useScreenSize();

  const { src, isFontAwesome, alt } = icon;

  return (
    <div
      className={`${Style["technology-item-wrapper"]} ${
        isReversed ? Style["reverse"] : ""
      }`}
    >
      <div
        className={`${Style["technology-item"]} ${
          isReversed ? Style["reverse"] : ""
        }`}
      >
        {isFontAwesome ? (
          <FontAwesomeIcon
            icon={src}
            size={isExtraSmallSize ? "1й" : "2x"}
            color="#414141"
          />
        ) : (
          <img
            className={
              isExtraSmallSize
                ? Style["technology-icon-xl"]
                : Style["technology-icon-2x"]
            }
            src={src}
            alt={alt}
          />
        )}
        <p>{name}</p>
        <div></div>
        <div className={Style["technology-bullet-point-container"]}>
          <span className={Style["technology-bullet-point"]}></span>
        </div>
      </div>
    </div>
  );
};

export default TechnologyListItem;
