import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import Style from "./Header.module.css";
import useScreenSize from "../../hooks/useScreenSize";
import { slide as Menu } from "react-burger-menu";

import "./HamburgerMenu.css";
import PrimaryButton from "../UI/Buttons/PrimaryButton";

const Header = () => {
  const { isMediumSize, isSmallSize } = useScreenSize();

  const [open, setOpen] = useState(false);

  const showHamburgerMenu = isMediumSize || isSmallSize;

  const desktopNavigation = (
    <nav className={Style.nav}>
      <NavLink exact to="/" activeClassName={Style["active"]}>
        Home
      </NavLink>
      <NavLink exact to="/services" activeClassName={Style["active"]}>
        Services
      </NavLink>
      <NavLink exact to="/technologies" activeClassName={Style["active"]}>
        Technologies
      </NavLink>
      <NavLink exact to="/about-us" activeClassName={Style["active"]}>
        About us
      </NavLink>
      <PrimaryButton
        onClick={() =>
          window.open("https://calendly.com/contact-warenex/30min")
        }
        padding="1rem 2rem"
        borderRadius="30px"
      >
        Book a meeting
      </PrimaryButton>
    </nav>
  );

  const mobileViewNavigation = (
    <Menu
      right
      styles={{ position: "absolute", top: 0 }}
      isOpen={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <NavLink onClick={() => setOpen(false)} to="/">
        Home
      </NavLink>
      <NavLink onClick={() => setOpen(false)} to="/services">
        Services
      </NavLink>
      <NavLink onClick={() => setOpen(false)} to="/technologies">
        Technologies
      </NavLink>
      <NavLink onClick={() => setOpen(false)} to="/about-us">
        About us
      </NavLink>
      <hr></hr>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://calendly.com/contact-warenex/30min"
      >
        Book a meeting
      </a>
    </Menu>
  );
  return (
    <header className={Style.header}>
      <div className={Style["header__inner-container"]}>
        <Link to="/" className={Style["nav-logo"]}>
          <img src="nav-logo.png" alt="Navigation logo" />
        </Link>
        {showHamburgerMenu ? mobileViewNavigation : desktopNavigation}
      </div>
    </header>
  );
};

export default Header;
