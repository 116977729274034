import TechnologiesList from "./TechnologiesList";
import {
  faReact,
  faAngular,
  faCss3,
  faHtml5,
  faJs,
  faDocker,
  faJenkins,
  faWordpress,
  faShopify,
} from "@fortawesome/free-brands-svg-icons";

import {
  cSharpIcon,
  dotNetCoreIcon,
  dotNetMvcIcon,
  kubernetesIcon,
} from "../../icons";

import Style from "./Technologies.module.css";
import { forwardRef } from "react";

const frontEndTechnologies = [
  { name: "React", icon: { src: faReact, isFontAwesome: true } },
  { name: "Angular", icon: { src: faAngular, isFontAwesome: true } },
  { name: "CSS3", icon: { src: faCss3, isFontAwesome: true } },
  { name: "HTML5", icon: { src: faHtml5, isFontAwesome: true } },
  { name: "JavaScript / TypeScript", icon: { src: faJs, isFontAwesome: true } },
];

const backEndTechnologies = [
  { name: "C#", icon: { src: cSharpIcon, isFontAwesome: false } },
  {
    name: "ASP.NET Core Web API",
    icon: { src: dotNetCoreIcon, isFontAwesome: false },
  },
  {
    name: "ASP.NET Core MVC",
    icon: { src: dotNetMvcIcon, isFontAwesome: false },
  },
];

const devOpsAndCloudTechnologies = [
  { name: "Kubernetes", icon: { src: kubernetesIcon, isFontAwesome: false } },
  { name: "Docker", icon: { src: faDocker, isFontAwesome: true } },
  { name: "Jenkins", icon: { src: faJenkins, isFontAwesome: true } },
];

const mobileTechnologies = [
  { name: "React Native", icon: { src: faReact, isFontAwesome: true } },
];

const otherTechnologies = [
  { name: "Wordpress", icon: { src: faWordpress, isFontAwesome: true } },
];

const TechnologiesListsSection = forwardRef((props, ref) => {
  return (
    <div ref={ref} className={Style["technology-lists-section"]}>
      <div className={Style["background-image"]}></div>
      <div className={Style["technology-lists-container"]}>
        <TechnologiesList
          listName="Front End"
          technologies={frontEndTechnologies}
        />
        <TechnologiesList
          listName="Back End"
          technologies={backEndTechnologies}
          isReversed
        />
        <TechnologiesList
          listName="DevOps"
          technologies={devOpsAndCloudTechnologies}
        />
        <TechnologiesList
          listName="Mobile"
          technologies={mobileTechnologies}
          isReversed
        />
        <TechnologiesList listName="Other" technologies={otherTechnologies} />
      </div>
    </div>
  );
});

export default TechnologiesListsSection;
