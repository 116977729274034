import { useState } from "react";
import useScreenSize from "../../hooks/useScreenSize";

import Style from "./IntroductionWithImages.module.css";

const MobileView = ({ text, imageSrc }) => {
  return (
    <div className={Style["introduction-with-image-row-mobile"]}>
      <div
        className={`${Style["row-image-mobile"]} ${Style["w-100"]}`}
        style={{ backgroundImage: `url(${imageSrc})` }}
      ></div>
      <p className={Style["text-mobile"]}>{text}</p>
    </div>
  );
};

const DesktopView = ({ text, imageSrc, reversed, boxColor }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`${Style["introduction-with-image-row"]} ${Style["gray-out"]}`}
      style={{ flexDirection: reversed ? "row-reverse" : "row" }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className={`${Style["row-text-container"]} ${Style["w-50"]}`}
        style={{ backgroundColor: hovered ? boxColor : "#1C2B33" }}
      >
        <p>{text}</p>
      </div>
      <div
        className={`${Style["row-image"]} ${Style["w-50"]} ${
          !hovered ? "gray-out" : ""
        }`}
        style={{ backgroundImage: `url(${imageSrc})` }}
      ></div>
    </div>
  );
};

const IntroductionWithImageRow = ({ text, imageSrc, reversed, boxColor }) => {
  const { isSmallSize, isMediumSize } = useScreenSize();

  const isMobile = isSmallSize || isMediumSize;

  return isMobile ? (
    <MobileView text={text} imageSrc={imageSrc} />
  ) : (
    <DesktopView
      text={text}
      imageSrc={imageSrc}
      reversed={reversed}
      boxColor={boxColor}
    />
  );
};

export default IntroductionWithImageRow;
