import TechnologyListItem from "./TechnologyListItem";

import Style from "./Technologies.module.css";

const TechnologiesList = ({ listName, technologies, isReversed }) => {
  return (
    <div className={Style["technologies-list"]}>
      <div
        className={`${Style["technology-list-heading-wrapper"]} ${
          isReversed ? Style["reverse"] : ""
        }`}
      >
        <div
          className={`${Style["technology-list-heading"]} ${
            isReversed ? Style["reverse"] : ""
          }`}
        >
          <h2>{listName}</h2>
          <div></div>
          <span className={Style["technology-list-bullet-point"]}></span>
        </div>
      </div>
      <div>
        {technologies &&
          technologies.map((t) => {
            return (
              <TechnologyListItem
                key={t.name}
                name={t.name}
                icon={t.icon}
                isReversed={isReversed}
              />
            );
          })}
      </div>
    </div>
  );
};

export default TechnologiesList;
