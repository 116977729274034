import { useRef } from "react";
import TechnologiesListsSection from "../components/Technologies/TechnologiesListsSection";
import { useEffect } from "react";
import DescriptionWithImageSection from "../components/Common/DescriptionWithImage";

const Technologies = () => {
  const ref = useRef();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div>
      <DescriptionWithImageSection
        title={
          <h2>
            We believe in future of <br />{" "}
            <span style={{ color: "#0668E1" }}>technology</span>
          </h2>
        }
        paragraph={
          <p>
            We stay up-to-date with the latest and most effective technologies
            in the industry. Our commitment to utilizing the best tools enables
            us to deliver outstanding software solutions for our clients. Below
            is a comprehensive list of the technologies we utilize to bring your
            ideas to life, ensuring quality, efficiency, and innovation in every
            project.
          </p>
        }
        showButton={true}
        imageInfo={{
          link: "/technology_header.webp",
          description: "Technology introduction picture",
        }}
        onBtnClick={() => {
          let dims = ref.current.getBoundingClientRect();
          window.scrollTo(window.scrollX, dims.top - 50);
        }}
      />
      <TechnologiesListsSection ref={ref} />
    </div>
  );
};

export default Technologies;
