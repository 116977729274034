import Style from "./PrimaryButton.module.css";

const PrimaryButton = ({
  onClick,
  children,
  padding,
  width,
  height,
  borderRadius,
  fontSize,
  style,
}) => {
  return (
    <button
      className={Style["primary-btn"]}
      onClick={onClick}
      style={{ width, height, borderRadius, fontSize, padding, ...style }}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
