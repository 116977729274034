import IntroductionWithImageRow from "./IntroductionWithImageRow";

import Style from "./IntroductionWithImages.module.css";

const IntroductionWithImagesSection = () => {
  return (
    <div className={Style["introduction-with-images-wrapper"]}>
      <IntroductionWithImageRow
        imageSrc="/road.webp"
        text="We strive to build long-term relationships with our clients. Our mission is to provide exceptional service that exceeds our clients' expectations and helps them succeed in their business."
        boxColor="#003CA2"
      />
      <IntroductionWithImageRow
        imageSrc="/montain-peaks.webp"
        text="We believe that our success is a direct result of our clients' success, which is why we go above and beyond to deliver quality solutions that meet their specific needs."
        boxColor="#00A2FF"
        reversed
      />
      <IntroductionWithImageRow
        imageSrc="/teamwork-table.webp"
        text="Our team uses a collaborative approach to software development, which involves continuous communication and feedback to ensure that we are meeting our clients' expectations."
        boxColor="#0DA8CF"
      />
      <IntroductionWithImageRow
        imageSrc="/call.webp"
        text="If you're interested in learning more about our services or how we can help your business, please don't hesitate to contact us. We look forward to the opportunity to work with you."
        reversed
        boxColor="#035DCB"
      />
    </div>
  );
};

export default IntroductionWithImagesSection;
