import Style from "./Services.module.css";

const Service = ({ title, titleColor, description }) => {
  return (
    <div className={Style["service"]}>
      <h3 style={{ color: titleColor }}>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default Service;
