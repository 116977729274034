// Carousel.js
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Style from "./Strengths.module.css";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const StrengthsCarousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const rgbaBgColor = (hexColor, opacity) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  return (
    <div className={Style["carousel-container"]}>
      <div className={Style["carousel-image-wrapper"]}>
        <img src={items[currentIndex].imageSrc} alt="carousel item" />
      </div>
      <div
        className={Style["carousel-description-wrapper"]}
        style={{
          backgroundColor: rgbaBgColor(
            items[currentIndex].boxColor ?? "#000",
            0.5
          ),
        }}
      >
        <h3>{items[currentIndex].heading}</h3>
        <p>{items[currentIndex].text}</p>
      </div>
      <button
        className={`${Style["carousel-arrow"]} ${Style["carousel-arrow-left"]}`}
        onClick={handlePrevClick}
      >
        <FontAwesomeIcon
          opacity={0.7}
          color="#fff"
          size="2x"
          icon={faAngleLeft}
        />
      </button>
      <button
        className={`${Style["carousel-arrow"]} ${Style["carousel-arrow-right"]}`}
        onClick={handleNextClick}
      >
        <FontAwesomeIcon
          opacity={0.7}
          color="#fff"
          size="2x"
          icon={faAngleRight}
        />
      </button>
    </div>
  );
};

export default StrengthsCarousel;
