import useScreenSize from "../../../hooks/useScreenSize";
import Strength from "./Strength";
import Style from "./Strengths.module.css";
import StrengthsCarousel from "./StrengthsCarousel";

const StrengthsSection = () => {
  const { isSmallSize, isMediumSize } = useScreenSize();

  const isMobile = isSmallSize || isMediumSize;

  const strengths = [
    {
      heading: "KNOWLEDGE",
      text: "We continuously improve ourselves by staying up-to-date with the latest technologies and trends in the IT industry.",
      boxColor: "#003CA2",
      imageSrc: "/knowledge.webp",
    },
    {
      heading: "RESPONSIBILITY",
      text: "We are fully committed to the success of your projects, prioritizing accountability and transparency.",
      boxColor: "#00A2FF",
      imageSrc: "/responsibility.webp",
    },
    {
      heading: "TEAMWORK",
      text: "Teamwork is the core of our company. We share new ideas, skills and experience to grow together.",
      boxColor: "#00CBFF",
      imageSrc: "/teamwork.webp",
    },
    {
      heading: "PROFESSIONALISM",
      text: "We aim to maintain the highest standards of excellence, ethics, and communication in all our work.",
      boxColor: "#035DCB",
      imageSrc: "/leadership.webp",
    },
  ];

  return (
    <div className={Style["strengths-section-wrapper"]}>
      <h2>OUR STRENGTHS</h2>
      <div>
        {isMobile ? (
          <StrengthsCarousel items={strengths} />
        ) : (
          strengths.map((s) => {
            return (
              <Strength
                key={s.heading}
                heading={s.heading}
                text={s.text}
                boxColor={s.boxColor}
                imageSrc={s.imageSrc}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default StrengthsSection;
