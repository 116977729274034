import { useRef } from "react";
import ContactsSection from "../components/Contacts/ContactsSection";
import ServicesSection from "../components/Home/Services/ServicesSection";
import StrengthsSection from "../components/Home/Strengths/StrengthsSection";
import BrandSection from "../components/Home/Brand/BrandSection";
import { useEffect } from "react";
import ReasonsForActionsSection from "../components/Home/ReasonsForActions/ReasonsForActionsSection";
import AchievementsSection from "../components/Home/AchievementsSection/AchievementsSection";

const Home = ({ scrollToServices, scrollToContactUs }) => {
  const servicesSectionRef = useRef();
  const contactsSectionRef = useRef();

  useEffect(() => {
    if (!(scrollToServices || scrollToContactUs)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }
  }, [scrollToServices, scrollToContactUs]);

  if (scrollToServices) {
    setTimeout(() => {
      if (servicesSectionRef.current) {
        servicesSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  } else if (scrollToContactUs) {
    setTimeout(() => {
      if (contactsSectionRef.current) {
        contactsSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  }

  return (
    <div>
      <BrandSection />
      <ServicesSection ref={servicesSectionRef} />
      <ReasonsForActionsSection />
      {/* <AchievementsSection /> */}
      <StrengthsSection />
      <ContactsSection ref={contactsSectionRef} />
    </div>
  );
};

export default Home;
