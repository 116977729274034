import Style from "./Services.module.css";

const CompanyDescription = () => {
  return (
    <div className={Style["company-description"]}>
      <h2>WHAT TO EXPECT</h2>
      <p>
        Smooth process from start to finish, with careful attention to every
        detail, clear communication, and a strong focus on making sure you're
        happy.
      </p>
      <p>
        Working with us means more than just getting a product – it's like
        having a trusted partner by your side, ensuring your ideas come to life
        just the way you want.
      </p>
    </div>
  );
};

export default CompanyDescription;
