import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import Style from "./Services.module.css";
import useScreenSize from "../../../hooks/useScreenSize";

const MobileBox = ({
  text,
  icon,
  serviceIconAltText = "Service",
  iconSize = 5,
}) => {
  return (
    <div className={Style["box-wrapper"]}>
      <div className={Style["box-mobile"]}>
        <div className={Style["box-icon-container-mobile"]}>
          <img
            src={icon}
            alt={serviceIconAltText}
            style={{ width: `${iconSize}em` }}
          />
        </div>
      </div>
      <div className={Style["service-description"]}>
        <p>{text}</p>
      </div>
    </div>
  );
};

const DesktopBox = ({
  text,
  icon,
  serviceIconAltText = "Service",
  iconSize = 5,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`${Style.box} ${hovered ? Style["box-hovered"] : ""}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered ? (
        <p>{text}</p>
      ) : (
        <div className={Style["box-icon-container"]}>
          <img
            src={icon}
            alt={serviceIconAltText}
            style={{ width: `${iconSize}em` }}
          />
        </div>
      )}
      <div className={Style["arrow-container"]}>
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            transition: "all 0.5s ease",
            transform: `rotate(${hovered ? "0.5turn" : 0})`,
          }}
          color="#fff"
        />
      </div>
    </div>
  );
};

const Box = ({ text, icon, serviceIconAltText = "Service", iconSize = 5 }) => {
  const { isSmallSize, isMediumSize } = useScreenSize();

  const isMobile = isSmallSize || isMediumSize;

  return isMobile ? (
    <MobileBox
      text={text}
      icon={icon}
      serviceIconAltText={serviceIconAltText}
      iconSize={iconSize}
    />
  ) : (
    <DesktopBox
      text={text}
      icon={icon}
      serviceIconAltText={serviceIconAltText}
      iconSize={iconSize}
    />
  );
};

const Service = ({ title, text, icon }) => {
  return (
    <div className={Style["service-item"]}>
      <h3>{title}</h3>
      <Box text={text} icon={icon} />
    </div>
  );
};

export default Service;
