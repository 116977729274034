import { useEffect } from "react";
import DescriptionWithImageSection from "../components/Common/DescriptionWithImage";
import ContactsSection from "../components/Contacts/ContactsSection";
import ServicesSection from "../components/Services/ServicesSection";

const Services = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <div>
      <DescriptionWithImageSection
        title={
          <h2>
            Bring your vision to life with our{" "}
            <span style={{ color: "#0668E1" }}>services</span>
          </h2>
        }
        paragraph={
          <p>
            At Warenex, we provide effective software solutions designed to
            boost your business growth. We excel in crafting top-notch websites,
            mobile applications, and visually appealing designs, thoroughly
            tested to guarantee optimal performance. Let us take care of the
            technical side, so you can focus on your business success.
          </p>
        }
        imageInfo={{
          link: "/services.webp",
          description: "Services picture.",
        }}
      />
      <ServicesSection />
      <ContactsSection />
    </div>
  );
};

export default Services;
